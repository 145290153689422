<template>
  <div class="px-2 sm:px-10 md:px-20 lg:px-28 2xl:px-36 3xl:px-80 py-2 sm:py-8 md:py-12 lg:py-20">
    <div class="h-full pl-2 md:pl-0 pt-2 md:pt-0">
      <div class="px-1 py-1 capitalize">
        <div class="bg-black rounded-md">
          <div class="flex justify-between bg-gray-200 relative -top-3 -left-3 ">
            <div class="w-full border-2 border-black font-Jet font-bold text-2xl pl-4 rounded-l-md rounded-bl-none">
              <div class="py-5">
                <div class="flex space-x-2">
                  <div>
                    <div class="-mb-[62px] 2xl:-mb-[88px] 3xl:-mb-16 ml-[10px] 2xl:ml-[16px] 3xl:ml-[15px]">
                      <div class="w-[50px] 2xl:w-[72px] 3xl:w-[70px] h-[50px] 2xl:h-[72px] 3xl:h-[70px] rounded-full">
                      </div>
                    </div>
                    <div class="z-50">
                      <img src="../../public/bombs.png" alt="" class="w-16 2xl:w-20 3xl:w-16 h-16 2xl:h-20 3xl:h-16">
                    </div>
                  </div>
                  <div class="font-Karantina uppercase font-bold text-xl tracking-wider">
                    <h1>Pollacheial</h1>
                    <h1 class="-mt-2">Networks</h1>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="border-t-2 border-r-2 border-b-2 border-black px-4 pt-7 w-[110px] sm:w-[130px] rounded-r-md rounded-br-none">
              <div class="space-y-2">
                <div class="flex justify-around space-x-1">
                  <div class="bg-black w-[6px] sm:w-[8px] h-[6px] sm:h-[8px]"></div>
                  <div class="bg-black w-[6px] sm:w-[8px] h-[6px] sm:h-[8px]"></div>
                  <div class="bg-black w-[6px] sm:w-[8px] h-[6px] sm:h-[8px]"></div>
                </div>
                <div class="flex justify-around space-x-1">
                  <div class="bg-black w-[6px] sm:w-[8px] h-[6px] sm:h-[8px]"></div>
                  <div class="bg-black w-[6px] sm:w-[8px] h-[6px] sm:h-[8px]"></div>
                  <div class="bg-black w-[6px] sm:w-[8px] h-[6px] sm:h-[8px]"></div>
                </div>
                <div class="flex justify-around space-x-1">
                  <div class="bg-black w-[6px] sm:w-[8px] h-[6px] sm:h-[8px]"></div>
                  <div class="bg-black w-[6px] sm:w-[8px] h-[6px] sm:h-[8px]"></div>
                  <div class="bg-black w-[6px] sm:w-[8px] h-[6px] sm:h-[8px]"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="border-b-2 border-l-2 border-r-2 border-black px-4 md:px-10 py-10 bg-gray-200 relative -top-3 -left-3 rounded-bl-md rounded-br-md">
            <div>
              <div>
                <h1 class="font-Jet font-bold text-2xl pt-10">Instrument Survey</h1>
                <h1 class="font-Jet font-bold text-sm md:text-lg">Sosial & Kemasyarakatan</h1>
              </div>
              <h1 class="font-Jet font-bold text-xl pt-10">
                Hallo, Para Responden.
                <span class="font-Jet text-sm font-bold">
                  Terima Kasih telah menjadi bagian dari kami dalam survey kali ini. Kami bergerak bersama anda untuk
                  menentukan arah masa depan kota Bima yang lebih sejahtera.
                </span>
              </h1>
              <div class="pt-10">
                <h1 class="font-Jet font-bold text-lg">Isi Data Diri Anda</h1>
                <h1 class="font-Jet font-bold text-sm">Silahkan untuk mengisi kolom-kolom pertanyaan mengenai data diri
                  anda</h1>
              </div>
              <div class="pt-6 font-Jet font-bold text-base">
                <div>
                  <div>
                    <div>
                      <h1>Siapakah Nama Anda?</h1>
                      <div class="flex pt-2">
                        <div class="bg-black rounded-md">
                          <input type="text" v-model="nama"
                            class="w-[19.5rem] lg:w-[20rem] xl:w-[32rem] border-2 border-black bg-gray-200 rounded-md relative -left-1 -top-1 pl-4 text-base md:text-sm py-2"
                            placeholder="Nama Lengkap">
                        </div>
                      </div>
                    </div>
                    <div class="pt-10">
                      <h1>Berapa Umur Anda?</h1>
                      <div class="flex justify-start space-x-[6.5rem] md:space-x-[16.2rem] pl-2 pt-2">
                        <div>
                          <div class="flex pt-2 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="umur" :value="'17 - 26'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]"
                                @change="filterPertanyaanByUmur">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">17 - 26</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="umur" :value="'27 - 42'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]"
                                @change="filterPertanyaanByUmur">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">27 - 42</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="umur" :value="'> 43'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]"
                                @change="filterPertanyaanByUmur">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">> 43</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pt-10">
                      <h1>Jenis Kelamin Anda?</h1>
                      <div class="flex justify-start space-x-52 pl-2 pt-2">
                        <div>
                          <div class="flex pt-2 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="jenisKelamin" :value="'Laki-Laki'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">Laki - Laki</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="jenisKelamin" :value="'Perempuan'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">Perempuan</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pt-10">
                      <h1>Apa Pekerjaan Anda?</h1>
                      <div class="flex justify-start space-x-[55px] md:space-x-[265px] pl-2 pt-2">
                        <div>
                          <div class="flex pt-2 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pekerjaan" :value="'Guru'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">Guru</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pekerjaan" :value="'PNS'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">PNS</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pekerjaan" :value="'Honorer'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">Honorer</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pekerjaan" :value="'Buruh'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">Buruh</h1>
                          </div>
                          <div class="flex pt-2 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pekerjaan" :value="'Pelajar atau Mahasiswa'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">Pelajar atau Mahasiswa</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pekerjaan" :value="'Irt'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">Irt</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pekerjaan" :value="'Karyawan Swasta'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">Karyawan Swasta</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pekerjaan" :value="'Wirausaha'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">Wirausaha</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pekerjaan" :value="'Petani'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">Petani</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pekerjaan" :value="'Nelayan'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">Nelayan</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pekerjaan" :value="'Pekerjaan Lainnya'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">Lainya</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pt-10">
                      <h1>Berapa Total Pendapatan Anda Dalam Sebulan?</h1>
                      <div class="flex justify-start space-x-[35px] md:space-x-[255px] pl-2 pt-2">
                        <div>
                          <div class="flex pt-2 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pendapatan" :value="'Kurang Dari Rp.500.000'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-[15px] md:text-sm font-bold">Kurang Dari Rp.500.000 </h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pendapatan" :value="'Rp.500.000 - 1.000.000'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-[15px] md:text-sm font-bold">Rp.500.000 - 1.000.000</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pendapatan" :value="'Rp.1.000.000 - 3.000.000'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-[15px] md:text-sm font-bold">Rp.1.000.000 - 3.000.000</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pendapatan" :value="'Rp.3.000.000 - 5.000.000'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-[15px] md:text-sm font-bold">Rp.3.000.000 - 5.000.000</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pendapatan" :value="'Rp.5.000.000 - 10.000.000'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-[15px] md:text-sm font-bold">Rp.5.000.000 - 10.000.000</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pendapatan" :value="'> Rp.10.000.000'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-[15px] md:text-sm font-bold">Lebih dari Rp.10.000.000</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pt-10">
                      <h1>Apa Pendidikan Terakhir Anda?</h1>
                      <div class="flex justify-start space-x-[2.8rem] md:space-x-[290px] pl-2 pt-2">
                        <div>
                          <div class="flex pt-2 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pendidikan" :value="'SD'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">SD</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pendidikan" :value="'SMP'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">SMP</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pendidikan" :value="'SMA'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">SMA</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pendidikan" :value="'S1'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">S1</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pendidikan" :value="'S2'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">S2</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pendidikan" :value="'Doktor'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">Doktor</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="pendidikan" :value="'Tidak Sekolah'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">Tidak Sekolah</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pt-10">
                      <h1>Apa Status Pernikahan Anda Sekarang?</h1>
                      <div class="flex justify-start space-x-[225px] pl-2 pt-2">
                        <div>
                          <div class="flex pt-2 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="statusKawin" :value="'Belum Menikah'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">Belum Menikah</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="statusKawin" :value="'Menikah'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">Menikah</h1>
                          </div>
                          <div class="flex pt-6 space-x-3">
                            <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                              <input type="radio" v-model="statusKawin" :value="'Cerai'"
                                class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7x]">
                            </div>
                            <h1 class="font-Jet text-sm font-bold">Cerai</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex pt-8 w-full">
                      <div>
                        <div>
                          <div>
                            <h1 class="text-start font-Jet text-lg font-bold pb-2">Kecamatan</h1>
                            <select v-model="kecamatan"
                              class="border-2 border-black bg-gray-200 rounded-md w-full h-10 pl-3 relative -left-1 -top-1 py-2 text-start text-lg font-bold font-Jet">
                              <option value="Asakota">Asakota</option>
                              <option value="Rasanae Barat">Rasanae Barat</option>
                              <option value="Rasanae Timur">Rasanae Timur</option>
                              <option value="Raba">Raba</option>
                              <option value="Mpunda">Mpunda</option>
                            </select>
                          </div>
                        </div>
                        <div class="pt-6">
                          <div>
                            <h1 class="text-start font-Jet text-lg font-bold pb-2">Kelurahan</h1>
                            <select v-model="kelurahan" v-if="kecamatan"
                              class="border-2 border-black bg-gray-200 rounded-md w-full h-10 pl-3 relative -left-1 -top-1 py-1 text-start text-lg font-bold font-Jet">
                              <option v-for="kelurahanOption in kelurahanOptions[kecamatan]" :key="kelurahanOption"
                                :value="kelurahanOption">{{ kelurahanOption }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pt-10">
                  <div>
                    <div class="mr-6 opacity-70">
                      <div class="w-full h-[5px] bg-black"></div>
                      <div class="w-full h-[2.5px] bg-black mt-[1.5px]"></div>
                    </div>
                    <div class="pt-2">
                      <h1 class="font-Jet font-bold text-xl pt-2">Pertanyaan Sosial dan kemasyarakatan</h1>
                    </div>
                    <div class="pt-6">
                      <div class="pl-5 pr-4">
                        <div>
                          <div class="w-auto py-2 text-sm md:text-base font-bold space-y-4">
                            <div v-for="(ask, index) in pertanyaan" :key="index">
                              <div class="pb-6 sm:pb-4 -mx-4 pr-4 md:pr-10">
                                <label class="text-base" v-html="highlightKeywords(ask.pertanyaan)"></label>
                                <div v-if="ask.imagePaths && ask.imagePaths.length > 0" class="pt-6 space-y-4">
                                  <div v-for="(option, optIndex) in ask.multipleChoise" :key="optIndex"
                                    class="flex items-center space-x-4 pl-2">
                                    <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                                      <input
                                        class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7px]"
                                        type="radio" :id="'jawaban-' + index + '-' + optIndex" v-model="jawaban[index]"
                                        :value="option">
                                    </div>
                                    <h1 :for="'jawaban-' + index + '-' + optIndex">{{ option }}</h1>
                                    <img :src="getGambar(ask.imagePaths[optIndex])" alt="Gambar Kandidat"
                                      class="border-2 border-black w-28 h-28 object-cover">
                                  </div>
                                  <div v-if="jawaban[index].includes('Lainnya')" class="bg-black rounded-md">
                                    <input type="text" v-model="jawabanLainnya[index]"
                                      class="border border-black bg-gray-200 rounded-md w-full relative -left-1 -top-1 pl-4 py-2"
                                      placeholder="Isi jawaban lainnya">
                                  </div>
                                </div>
                                <div v-else-if="ask.multipleChoise.length > 0" class="pt-6 space-y-4">
                                  <div v-for="(option, optIndex) in ask.multipleChoise" :key="optIndex"
                                    class="space-x-2 space-y-4 flex pl-2">
                                    <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                                      <input
                                        class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7px]"
                                        type="radio" :id="'jawaban-' + index + '-' + optIndex" v-model="jawaban[index]"
                                        :value="option">
                                    </div>
                                    <h1 class="relative -top-4" :for="'jawaban-' + index + '-' + optIndex">{{ option }}
                                    </h1>
                                  </div>
                                  <div v-if="jawaban[index].includes('Lainnya')" class="bg-black rounded-md">
                                    <input type="text" v-model="jawabanLainnya[index]"
                                      class="border border-black bg-gray-200 rounded-md w-full relative -left-1 -top-1 pl-4 py-2"
                                      placeholder="Isi jawaban lainnya">
                                  </div>
                                </div>
                                <div v-if="ask.subPertanyaan.length > 0" class="pt-4 pl-4">
                                  <div v-for="(subAsk, subIndex) in ask.subPertanyaan" :key="subIndex" class="pb-4">
                                    <label class="text-6xl font-bold relative -left-4 ">.<span class="text-lg">{{
                                      subAsk.pertanyaan }}</span></label>
                                    <div v-for="(subOption, subOptIndex) in subAsk.multipleChoise" :key="subOptIndex"
                                      class="space-x-2 space-y-4 flex pl-2 pt-4">
                                      <div class="bg-black w-8 md:w-4 h-8 md:h-4 rounded-full md:rounded-sm">
                                        <input
                                          class="w-8 md:w-4 h-8 md:h-4 border-2 rounded-md border-black bg-gray-200 relative -left-[5px] -top-[5px] md:-top-[7px]"
                                          type="radio" :id="'sub-jawaban-' + index + '-' + subIndex + '-' + subOptIndex"
                                          v-model="jawabanSub[index][subIndex]" :value="subOption">
                                      </div>
                                      <h1 class="relative -top-4"
                                        :for="'sub-jawaban-' + index + '-' + subIndex + '-' + subOptIndex">{{ subOption
                                        }}</h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex justify-around space-x-4 pt-4">
                      <div @click="submitSurvey"
                        class="bg-black rounded-md shadow-black transition-transform duration-300 ease-in-out transform hover:scale-110 text-[17px] capitalize font-bold">
                        <div
                          class="border-2 relative -top-1 -left-1 bg-gray-200 hover:bg-gray-400 border-black text-center px-20 rounded-md">
                          <button>
                            <h1 class="py-2">Selesai</h1>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div v-if="isLoading"
                      class="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center">
                      <div class="loader"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Api from "@/services/api";

export default {
  data() {
    return {
      nama: '',
      umur: '',
      jenisKelamin: '',
      pekerjaan: '',
      pendapatan: '',
      pendidikan: '',
      statusKawin: '',
      headers: '',
      pertanyaan: [],
      jawaban: [],
      jawabanLainnya: [],
      jawabanSub: [],
      isLoading: false,
      kecamatan: "",
      kelurahanOptions: {
        'Asakota': ['Melayu', 'Jatiwangi', 'Jatibaru', 'Jatibaru Timur', 'Kolo', 'Ule'],
        'Rasanae Barat': ['Tanjung', 'Paruga', 'Sarae', 'Nae', 'Pane', 'Dara'],
        'Rasanae Timur': ['Kumbe', 'Lampe', 'Oi Fo’o', 'Kodo', 'Dodu', 'Lelamase', 'Nungga', 'Oimbo'],
        'Raba': ['Rontu', 'Penaraga', 'Penanae', 'Rite', 'Rabangodu Utara', 'Rabangodu Selatan', 'Rabadompu Timur', 'Rabadompu Barat', 'Ntobo', 'Kendo', 'Nitu'],
        'Mpunda': ['Sambinae', 'Panggi', 'Monggonao', 'Manggemaci', 'Penatoi', 'Lewirato', 'Sadia', 'Mande', 'Santi', 'Matakando']
      },
    };
  },
  mounted() {
    this.getPertanyaan();
  },
  methods: {
    getGambar(imagePaths) {
      return `https://pollacheialnetworks.com/${imagePaths}`;
    },
    async getPertanyaan() {
      try {
        const response = await Api.get("pertanyaan");
        this.allPertanyaan = response.data; // Menyimpan semua pertanyaan di sini
        this.filterPertanyaanByUmur(); // Memanggil filter saat data diterima
      } catch (error) {
        console.log(error);
      }
    },
    filterPertanyaanByUmur() {
      if (!this.umur) {
        this.pertanyaan = []; // Kosongkan pertanyaan jika umur belum dipilih
        return;
      }

      // Filter pertanyaan berdasarkan header yang sesuai dengan umur
      if (this.umur === "17 - 26") {
        this.pertanyaan = this.allPertanyaan.filter((ask) => ask.header === "Gen Z");
      } else if (this.umur === "27 - 42") {
        this.pertanyaan = this.allPertanyaan.filter((ask) => ask.header === "Milenial");
      } else if (this.umur === "> 43") {
        this.pertanyaan = this.allPertanyaan.filter((ask) => ask.header === "Boomer");
      }

      // Inisialisasi jawaban dan jawabanSub sesuai pertanyaan yang difilter
      this.jawaban = new Array(this.pertanyaan.length).fill([]); // Ensure it's an array
      this.jawabanLainnya = new Array(this.pertanyaan.length).fill('');
      this.jawabanSub = this.pertanyaan.map(p => new Array(p.subPertanyaan.length).fill([]));
    },
    highlightKeywords(text) {
      const keywords = ['Walikota Bima', 'Wakil Walikota Bima', 'Wakil'];
      let highlightedText = text;
      keywords.forEach(keyword => {
        const regex = new RegExp(`(${keyword})`, 'gi');
        highlightedText = highlightedText.replace(regex, '<span class="text-lg font-extrabold">$1</span>');
      });
      return highlightedText;
    },
    submitSurvey() {
      if (!this.umur || !this.jenisKelamin || !this.pekerjaan || !this.pendapatan || !this.pendidikan || !this.statusKawin || !this.jawaban) {
        alert('Silakan lengkapi semua pilihan sebelum melanjutkan!');
        this.isLoading = false;
        return;
      }

      this.isLoading = true;
      const datas = {
        nama: this.nama,
        kecamatan: this.kecamatan,
        kelurahan: this.kelurahan,
        umur: this.umur, // for radio button
        jenisKelamin: this.jenisKelamin, // for radio button
        pekerjaan: this.pekerjaan, // for radio button
        pendapatan: this.pendapatan, // for radio button
        pendidikan: this.pendidikan, // for radio button
        statusKawin: this.statusKawin, // for radio button
        jawaban: this.pertanyaan.map((ask, index) => {
          let userAnswers = this.jawaban[index];

          // Ensure userAnswers is an array
          if (!Array.isArray(userAnswers)) {
            userAnswers = [userAnswers];
          }

          userAnswers = userAnswers.filter(answer => answer !== 'Lainnya');

          if (this.jawaban[index].includes('Lainnya') && this.jawabanLainnya[index]) {
            userAnswers.push(this.jawabanLainnya[index]);
          }
          const subJawaban = ask.subPertanyaan.map((subAsk, subIndex) => ({
            pertanyaan: subAsk.pertanyaan,
            jawaban: this.jawabanSub[index][subIndex].join(", "),
          }));

          return {
            pertanyaan: ask.pertanyaan,
            jawaban: userAnswers.join(", "),
            subJawaban,
          };
        }),
      };

      if (this.nama) {
        Api.post('survey', datas)
          .then(response => {
            console.log(response.data);
            alert('Survei berhasil disimpan!');
            this.isLoading = false; // Set isLoading ke false setelah survei berhasil disimpan
            window.location.reload(); // Reload halaman
          })
          .catch(error => {
            console.error('Error:', error);
            this.isLoading = false; // Set isLoading ke false jika terjadi error
          });
      } else {
        alert('Silakan lengkapi semua input sebelum melanjutkan!');
        this.isLoading = false; // Set isLoading ke false jika input belum lengkap
      }
    }
  }

}
</script>