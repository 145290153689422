<template>
    <div v-if="!user" class="text-center">
        <div class="flex justify-center items-center h-screen">
            <h1>blank</h1>
        </div>
    </div>
    <div v-if="user" class="min-h-screen bg-gray-100 flex items-center justify-center p-6">
        <div class="bg-white shadow-md rounded-lg p-8 max-w-lg w-full">
            <div>
                <div class="mb-6">
                    <h1 class="text-2xl font-bold mb-4">Masukan Pertanyaan</h1>
                    <select v-model="head"
                        class="border-2 border-black bg-gray-200 rounded-md w-full h-10 pl-3 relative -left-1 -top-1 py-2 text-start text-lg font-bold font-Jet">
                        <option value="Gen X">Gen Z</option>
                        <option value="Milenials">milenials</option>
                        <option value="Gen X">Gen X</option>
                    </select>
                </div>
                <div class="mb-6">
                    <h1 class="text-2xl font-bold mb-4">Masukan Pertanyaan</h1>
                    <input type="text" v-model="add"
                        class="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500" />
                </div>
                <div v-if="!hasSubPertanyaan" class="mb-6">
                    <h1 class="text-xl font-semibold mb-4">Masukan pilihan jawaban</h1>
                    <div v-for="(choice, index) in multi" :key="index" class="flex items-center mb-2">
                        <input type="text" v-model="multi[index]"
                            class="w-full p-2 border border-gray-300 rounded mr-2 focus:outline-none focus:ring-2 focus:ring-blue-500" />
                        <button @click="removeChoice(index)"
                            class="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500">
                            Remove
                        </button>
                    </div>
                    <button @click="addChoice"
                        class="mt-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500">
                        Add Choice
                    </button>
                </div>
                <div>
                    <div v-for="(sub, subIndex) in subPertanyaan" :key="subIndex"
                        class="mb-6 pl-4 border-l-4 border-gray-300">
                        <input type="text" v-model="sub.pertanyaan" placeholder="Sub-Pertanyaan"
                            class="w-full p-2 border border-gray-300 rounded mb-2 focus:outline-none focus:ring-2 focus:ring-blue-500" />
                        <div v-for="(choice, choiceIndex) in sub.multipleChoise" :key="choiceIndex"
                            class="flex items-center mb-2">
                            <input type="text" v-model="sub.multipleChoise[choiceIndex]"
                                class="w-full p-2 border border-gray-300 rounded mr-2 focus:outline-none focus:ring-2 focus:ring-blue-500" />
                            <button @click="removeSubChoice(subIndex, choiceIndex)"
                                class="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500">
                                Remove
                            </button>
                        </div>
                        <div class="space-y-2">
                            <button @click="addSubChoice(subIndex)"
                                class="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500">
                                Add Choice
                            </button>
                            <button @click="removeSubPertanyaan(subIndex)"
                                class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500">
                                Remove Sub-Pertanyaan
                            </button>
                        </div>
                    </div>
                    <button @click="addSubPertanyaan"
                        class="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
                        Add Sub-Pertanyaan
                    </button>
                </div>
                <div class="pt-6">
                    <button @click="submitAsk"
                        class="w-full px-6 py-3 bg-indigo-500 text-white text-lg rounded hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                        <h1>Add Asker...</h1>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "@/services/api";

export default {
    data() {
        return {
            head: "",
            add: "",
            multi: [],
            subPertanyaan: []
        };
    },
    computed: {
        hasSubPertanyaan() {
            return this.subPertanyaan.length > 0;
        },
        user() {
            return this.$store.state.user;
        }
    },
    methods: {
        addChoice() {
            this.multi.push("");
        },
        removeChoice(index) {
            this.multi.splice(index, 1);
        },
        addSubPertanyaan() {
            this.subPertanyaan.push({ pertanyaan: "", multipleChoise: [] });
        },
        removeSubPertanyaan(index) {
            this.subPertanyaan.splice(index, 1);
        },
        addSubChoice(subIndex) {
            this.subPertanyaan[subIndex].multipleChoise.push("");
        },
        removeSubChoice(subIndex, choiceIndex) {
            this.subPertanyaan[subIndex].multipleChoise.splice(choiceIndex, 1);
        },
        async submitAsk() {
            try {
                const datas = {
                    header: this.head,
                    pertanyaan: this.add,
                    multipleChoise: this.hasSubPertanyaan ? [] : this.multi,
                    subPertanyaan: this.subPertanyaan
                };
                if (this.add && (this.multi.length || this.hasSubPertanyaan)) {
                    await Api.post("addPertanyaan", datas);
                    alert("Pertanyaan berhasil disimpan!");
                    this.add = "";
                    this.multi = [];
                    this.subPertanyaan = [];
                } else {
                    alert("Silakan lengkapi semua input sebelum melanjutkan!");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
    }
};
</script>


<!-- <template>
    <div>
        <div class="pl-4">
            <div>
                <h1>Masukan Pertanyaan</h1>
                <input type="text" v-model="add">
            </div>
            <div>
                <h1>Masukan pilihan jawaban</h1>
                <div v-for="(choice, index) in multi" :key="index" class="flex items-center space-y-2">
                    <input type="text" v-model="multi[index]" class="mr-2">
                    <button @click="removeChoice(index)">Remove</button>
                </div>
                <button @click="addChoice">Add Choice</button>
            </div>
            <div class="pt-4">
                <button @click="submitAsk" class="border-2 border-black px-6 py-2">
                    <h1>Add Asker...</h1>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "@/services/api";

export default {
    data() {
        return {
            add: "",
            multi: []
        }
    },
    methods: {
        addChoice() {
            this.multi.push("");
        },
        removeChoice(index) {
            this.multi.splice(index, 1);
        },
        async submitAsk() {
            try {
                const datas = {
                    pertanyaan: this.add,
                    multipleChoise: this.multi
                }
                if (this.add && this.multi.length) {
                    Api.post('addPertanyaan', datas)
                        .then(response => {
                            console.log(response.data);
                            alert('Pertanyaan berhasil disimpan!');
                            this.add = "";
                            this.multi = [];
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });
                } else {
                    console.log("err");
                }
            } catch (error) {
                console.log(error)
            }
        },
    }
}
</script> -->