<template>
    <div v-if="!user">
        <div class="flex justify-center h-screen items-center">
            blank
        </div>
    </div>

    <div v-if="user" class="container mx-auto px-4 py-4">
        <div @click="logout" class="mb-4">
            <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                Log Out
            </button>
        </div>
        <h1 class="text-2xl font-bold mb-4">Data Survey</h1>
        <p>Total Data: {{ filteredDataSurveys.length }}</p>

        <div class="mb-4">
            <label for="filterKecamatan" class="block text-sm font-medium text-gray-700">Filter Kecamatan</label>
            <select v-model="filterKecamatan" id="filterKecamatan"
                class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                <option value="">Semua Kecamatan</option>
                <option v-for="kecamatan in kecamatanOptions" :key="kecamatan" :value="kecamatan">{{ kecamatan }}
                </option>
            </select>
        </div>
        <div class="mb-4">
            <label for="filterKelurahan" class="block text-sm font-medium text-gray-700">Filter Kelurahan</label>
            <select v-model="filterKelurahan" id="filterKelurahan"
                class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                <option value="">Semua Kelurahan</option>
                <option v-for="kelurahan in kelurahanOptions" :key="kelurahan" :value="kelurahan">{{ kelurahan }}
                </option>
            </select>
        </div>

        <button v-if="!loading && !error && filteredDataSurveys.length" @click="downloadCSV"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4">
            Download CSV
        </button>
        <div v-if="loading" class="text-center">
            <p>Loading...</p>
        </div>
        <div v-else-if="error" class="text-center text-red-500">
            <p>Error: {{ error }}</p>
        </div>
        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col"
                                        class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        No</th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Nama</th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Umur</th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Jenis Kelamin</th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Pekerjaan</th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Pendapatan</th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Pendidikan</th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Status Kawin</th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Kecamatan</th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Kelurahan</th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        v-for="index in 21" :key="index">Pertanyaan {{ index }}</th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Delete Data</th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(survey, index) in paginatedData" :key="survey.IdRes">
                                    <td class="px-6 py-4 whitespace-nowrap">{{ (currentPage - 1) * pageSize + index + 1
                                        }}</td>
                                    <td class="px-6 py-4 whitespace-nowrap">{{ survey.nama }}</td>
                                    <td class="px-6 py-4 whitespace-nowrap">{{ survey.umur }}</td>
                                    <td class="px-6 py-4 whitespace-nowrap">{{ survey.jenisKelamin }}</td>
                                    <td class="px-6 py-4 whitespace-nowrap">{{ survey.pekerjaan }}</td>
                                    <td class="px-6 py-4 whitespace-nowrap">{{ survey.pendapatan }}</td>
                                    <td class="px-6 py-4 whitespace-nowrap">{{ survey.pendidikan }}</td>
                                    <td class="px-6 py-4 whitespace-nowrap">{{ survey.statusKawin }}</td>
                                    <td class="px-6 py-4 whitespace-nowrap">{{ survey.kecamatan }}</td>
                                    <td class="px-6 py-4 whitespace-nowrap">{{ survey.kelurahan }}</td>
                                    <td class="px-6 py-4 whitespace-nowrap" v-for="(jawaban, index) in survey.jawaban"
                                        :key="index">
                                        <p><strong>Pertanyaan:</strong> {{ jawaban.pertanyaan }}</p>
                                        <p><strong>Jawaban:</strong> {{ jawaban.jawaban }}</p>
                                        <div v-if="jawaban.subJawaban.length" class="mt-2">
                                            <h4 class="font-semibold">Sub Jawaban:</h4>
                                            <ul class="list-decimal ml-5">
                                                <li v-for="(subJawaban, subIndex) in jawaban.subJawaban"
                                                    :key="subIndex">
                                                    <p><strong>Pertanyaan:</strong> {{ subJawaban.pertanyaan }}</p>
                                                    <p><strong>Jawaban:</strong> {{ subJawaban.jawaban }}</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap"><button @click="deleteSurvey(survey.IdRes)"
                                            class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4">
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-end pb-10">
            <div class="py-3 space-x-10">
                <button @click="previousPage"
                    class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Previous</button>
                <button @click="nextPage"
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Next</button>
                <span class="ml-4">Page {{ currentPage }} of {{ totalPages }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "@/services/api";
import Papa from 'papaparse';

export default {
    data() {
        return {
            dataSurveys: [],
            loading: true,
            error: null,
            currentPage: 1,
            pageSize: 25,
            totalData: 0,
            filterKecamatan: '',
            filterKelurahan: ''
        };
    },
    computed: {
        paginatedData() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize;
            return this.filteredDataSurveys.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.filteredDataSurveys.length / this.pageSize);
        },
        filteredDataSurveys() {
            return this.dataSurveys.filter(survey => {
                return (!this.filterKecamatan || survey.kecamatan === this.filterKecamatan) &&
                    (!this.filterKelurahan || survey.kelurahan === this.filterKelurahan);
            });
        },
        kecamatanOptions() {
            return [...new Set(this.dataSurveys.map(survey => survey.kecamatan))];
        },
        kelurahanOptions() {
            return [...new Set(this.dataSurveys.map(survey => survey.kelurahan))];
        },
        user() {
            return this.$store.state.user;
        }
    },
    methods: {
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        async deleteSurvey(id) {
            try {
                const response = await Api.delete(`survey/${id}`);
                this.$toast.success(response.data.message, { position: "bottom-left", duration: 1000 });
                this.fetchDataSurveys(); // Fetch data again after deleting
            } catch (error) {
                console.error("Error deleting survey:", error);
                this.$toast.error("Failed to delete survey. Please try again later.", { position: "bottom-left", duration: 1000 });
            }
        },
        async fetchDataSurveys() {
            try {
                const response = await Api.get('surveys');
                this.dataSurveys = response.data;
                this.totalData = this.dataSurveys.length;
            } catch (error) {
                this.error = 'Failed to fetch data surveys. Please try again later.';
                console.error("Error fetching data surveys:", error);
            } finally {
                this.loading = false;
            }
        },
        async logout() {
            try {
                await this.$store.dispatch("logout");
                this.$toast.success("Successfully logged out.", { position: "bottom-left", duration: 1000 });
                await this.$router.push("/login");
            } catch (e) {
                console.log(e);
                this.$toast.error("Couldn't log out.", { position: "bottom-left", duration: 1000 });
            }
        },
        downloadCSV() {
            const structuredData = this.filteredDataSurveys.map(survey => {
                const baseInfo = {
                    IdRes: survey.IdRes,
                    Nama: survey.nama,
                    Umur: survey.umur,
                    'Jenis Kelamin': survey.jenisKelamin,
                    Pekerjaan: survey.pekerjaan,
                    Pendapatan: survey.pendapatan,
                    Pendidikan: survey.pendidikan,
                    'Status Kawin': survey.statusKawin,
                    Kecamatan: survey.kecamatan,
                    Kelurahan: survey.kelurahan
                };

                survey.jawaban.forEach((jawaban, index) => {
                    baseInfo[`Pertanyaan ${index + 1}`] = jawaban.pertanyaan;
                    baseInfo[`Jawaban ${index + 1}`] = jawaban.jawaban;
                    jawaban.subJawaban.forEach((subJawaban, subIndex) => {
                        baseInfo[`Sub Jawaban ${index + 1}.${subIndex + 1}`] = `${subJawaban.pertanyaan}: ${subJawaban.jawaban}`;
                    });
                });

                return baseInfo;
            });

            const csv = Papa.unparse(structuredData, {
                header: true
            });

            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', 'data_surveys.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },
    },
    created() {
        this.fetchDataSurveys();
    }
};
</script>