import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView";
import LoginView from "@/views/LoginView";
import RegisterView from "@/views/RegisterView";
import adds from "@/views/AddQuestion";
import store from "@/services/store";
import getDatas from "@/views/getDatas";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
      authRequired: false,
      authForbidden: false,
    },
  },
  {
    path: "/get",
    name: "get",
    component: getDatas,
    meta: {
      title: "Real Time Data",
      authRequired: false,
      authForbidden: false,
    },
  },
  {
    path: "/add",
    name: "Add",
    component: adds,
    meta: {
      title: "Adds",
      authRequired: false,
      authForbidden: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title: "Login",
      authRequired: false,
      authForbidden: false,
    },
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    meta: {
      title: "Register",
      authRequired: false,
      authForbidden: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// routes protection
router.beforeEach(async (to, from, next) => {
  // update store if needed
  if (!store.state.isStoreUpdated) {
    await store.dispatch("updateStore");
  }

  // control routes
  const isAuthenticated = store.state.userLoggedIn;
  if (!isAuthenticated && to.meta.authRequired) {
    next({ name: "login" });
  } else if (isAuthenticated && to.meta.authForbidden) {
    next({ name: "user" });
  } else {
    next();
  }
});

router.afterEach((to) => {
  document.title = to.meta.title;
});

export default router;
